import React, { useState } from "react";
import { Button, Confirm } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { signOutUser } from "../AuthUtils";

const SignOutButton = () => {
  let history = useHistory();

  const [showAlert, setShowAlert] = useState(false);

  const onConfirm = () => {
    signOutUser().then(() => {
      history.push("/");
    });
  };

  return (
    <div style={{ float: "right" }}>
      <Button inverted onClick={() => setShowAlert(true)}>
        Sign Out
      </Button>
      {showAlert && (
        <Confirm
          open={showAlert}
          confirmButton="Log Out"
          cancelButton="Cancel"
          onCancel={() => setShowAlert(false)}
          onConfirm={onConfirm}
        />
      )}
    </div>
  );
};

export default SignOutButton;
