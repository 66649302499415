import React from "react";
import { Button, Card, Input } from "semantic-ui-react";
import { FeedItem } from "../FeedScreen";

interface FeedCardProps {
  item: FeedItem;
  onXClick: (item: FeedItem) => void;
}

export const FeedTipCard = ({item, onXClick}: FeedCardProps) => {
  return (
    <Card key={item.item_id} style={{ boxShadow: "none" }}>  
      <Card.Content extra style={{ backgroundColor: "#a70000" }}>
        <Button circular icon='times' style={{ marginTop: "-10px", marginRight: "-10px", position:"absolute", top:0, right:0}} 
        onClick={() => onXClick(item)} />
        <Card.Description style={{ color: "white", textAlign: "left" }}>
          Tip
        </Card.Description>
      </Card.Content>
      <Card.Content>
        <Card.Header textAlign="left">{item.title}</Card.Header>
        <Card.Description textAlign="left">{item.body}</Card.Description>
      </Card.Content>
    </Card>
  );
};


export const FeedFactCard = ({item, onXClick}: FeedCardProps) => {
  return (
    <Card key={item.item_id} style={{ boxShadow: "none" }}>
      <Card.Content extra style={{ backgroundColor: "#7C40A9" }}>
        <Button circular icon='times' style={{ marginTop: "-10px", marginRight: "-10px", position:"absolute", top:0, right:0}} 
        onClick={() => onXClick(item)} />
        <Card.Description style={{ color: "white", textAlign: "left" }}>
          Fact
        </Card.Description>
      </Card.Content>
      <Card.Content>
        <Card.Header textAlign="left">{item.title}</Card.Header>
        <Card.Description textAlign="left">{item.body}</Card.Description>
      </Card.Content>
    </Card>
  );
};

export const FeedQACard = ({item, onXClick}: FeedCardProps) => {
  return (
    <Card key={item.item_id} style={{ boxShadow: "none" }}>
      <Card.Content extra style={{ backgroundColor: "#3cdfff" }}>
        <Button circular icon='times' style={{ marginTop: "-10px", marginRight: "-10px", position:"absolute", top:0, right:0}} 
        onClick={() => onXClick(item)} />
        <Card.Description style={{ color: "white", textAlign: "left" }}>
          Q+A
        </Card.Description>
      </Card.Content>
      <Card.Content>
        <Card.Header textAlign="left">
          {"What environment do work best in?"}
        </Card.Header>
        <br />
        <Input fluid icon="pencil" placeholder="A:..." />
      </Card.Content>
    </Card>
  );
};

export const FeedCheckInCard = ({item, onXClick}: FeedCardProps) => {
  return (
    <Card key={item.item_id} style={{ boxShadow: "none" }}>
      <Card.Content extra style={{ backgroundColor: "#0079FF" }}>
        <Button circular icon='times' style={{ marginTop: "-10px", marginRight: "-10px", position:"absolute", top:0, right:0}} 
        onClick={() => onXClick(item)} />
        <Card.Description style={{ color: "white", textAlign: "left" }}>
          Check-in
        </Card.Description>
      </Card.Content>
      <Card.Content>
        <Card.Header textAlign="left">{"What's going well? "}</Card.Header>
        <br />
        <Input fluid icon="trophy" placeholder="1:..." />
        <br />
        <Card.Header textAlign="left">{"What can be improved?"}</Card.Header>
        <br />
        <Input fluid icon="balance scale" placeholder="" />
      </Card.Content>
    </Card>
  );
};

export const FeedGoalCard = ({item, onXClick}: FeedCardProps) => {
  return (
    <Card key={item.item_id} style={{ boxShadow: "none" }}>
      <Card.Content extra style={{ backgroundColor: "#030081" }}>
        <Button circular icon='times' style={{ marginTop: "-10px", marginRight: "-10px", position:"absolute", top:0, right:0}} 
        onClick={() => onXClick(item)} />
        <Card.Description style={{ color: "white", textAlign: "left" }}>
          Goals
        </Card.Description>
      </Card.Content>
      <Card.Content>
        <Card.Header textAlign="left">{"Goals for the quarter"}</Card.Header>
        <br />
        <Input fluid placeholder="1:..." />
        <Input fluid placeholder="2:..." />
        <Input fluid placeholder="3:..." />
      </Card.Content>
    </Card>
  );
};

export const FeedEventReminderCard = ({item, onXClick}: FeedCardProps) => {
  return (
    <Card key={item.item_id} style={{ boxShadow: "none" }}>
      <Card.Content extra style={{ backgroundColor: "#FF6F00" }}>
        <Button circular icon='times' style={{ marginTop: "-10px", marginRight: "-10px", position:"absolute", top:0, right:0}} 
        onClick={() => onXClick(item)} />
        <Card.Description style={{ color: "white", textAlign: "left" }}>
          Reminder
        </Card.Description>
      </Card.Content>
      <Card.Content>
        <br />
        <Card.Header textAlign="left">{"text"}</Card.Header>
        <br />
        <Card.Description textAlign="left">{"description"}</Card.Description>
        <Card.Meta textAlign="left">{"dateApplied"}</Card.Meta>
      </Card.Content>
    </Card>
  );
};
