import React, { useState } from "react";
import { Button, Confirm, StrictButtonProps } from "semantic-ui-react";

interface DButtonProps extends StrictButtonProps {
  onClick: () => void;
}

const DButton: React.FC<DButtonProps> = (props: DButtonProps) => {
  let { onClick, ...restProps } = props;
  const [open, setOpen] = useState(false);

  const show = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button negative hidden={!open} {...restProps} onClick={show}>
        Delete Application
      </Button>
      {open && (
        <Confirm
          open={open}
          confirmButton="Delete"
          cancelButton="Cancel"
          onCancel={handleCancel}
          onConfirm={onClick}
        />
      )}
    </div>
  );
};

export default DButton;
