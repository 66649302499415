import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";
import CreatableSelect from "react-select/creatable";
import CalendarDateInput from "../../components/CalendarDateInput";
import "./appForm.css";

export interface ApplicationDetailsProps {
  date: Date | undefined;
  title: string;
  company: string;
  status: string;
  description: string;
  notes: string;
  importantDate: Date | undefined;
  importantText: string;
  applicationSite: string;
  applicationFind: string;
}

interface ApplicationFormProps {
  date?: Date;
  title?: string;
  company?: string;
  status?: string;
  description?: string;
  notes?: string;
  importantDate?: Date;
  importantText?: string;
  applicationSite?: string;
  applicationFind?: string;
  handler: (props: ApplicationDetailsProps) => void;
}

// TODO: pass back the props to the handler

const ApplicationForm = (props: ApplicationFormProps) => {
  const [date, setDate] = useState<Date>(props.date as Date);
  const [title, setTitle] = useState(props.title as string);
  const [company, setCompany] = useState(props.company as string);
  const [status, setStatus] = useState(props.status as string);
  const [description, setDescription] = useState(props.description as string);
  const [notes, setNotes] = useState(props.notes as string);
  const [importantDate, setImportantDate] = useState(
    props.importantDate as Date
  );

  const [importantText, setImportantText] = useState(
    props.importantText as string
  );
  const [applicationSite, setApplicationSite] = useState(
    props.applicationSite as string
  );
  const [applicationFind, setApplicationFind] = useState(
    props.applicationFind as string
  );

  var dateValue = date ? date.toLocaleString() : "";
  var importantDateValue = importantDate ? importantDate.toLocaleString() : "";

  useEffect(() => {
    // find better way.
    setDate(props.date as Date);
    setTitle(props.title as string);
    setCompany(props.company as string);
    setStatus(props.status as string);
    setDescription(props.description as string);
    setNotes(props.notes as string);
    setImportantDate(props.importantDate as Date);
    setImportantText(props.importantText as string);
    setApplicationSite(props.applicationSite as string);
    setApplicationFind(props.applicationFind as string);
  }, [props]);

  const onSubmitHandler = () => {
    const newProps: ApplicationDetailsProps = {
      date,
      title,
      company,
      status,
      description,
      importantDate,
      notes,
      importantText,
      applicationSite,
      applicationFind,
    };
    props.handler(newProps);
  };

  const statusOptions = [
    {
      key: "Want to Apply",
      label: "Want to Apply",
      value: "Want to Apply",
    },
    {
      key: "Applied",
      label: "Applied",
      value: "Applied",
    },
    {
      key: "Interview Scheduled",
      label: "Interview Scheduled",
      value: "Interview Scheduled",
    },
    {
      key: "Pending",
      label: "Pending",
      value: "Pending",
    },
    {
      key: 'Offer Extended',
      label: 'Offer Extended',
      value: 'Offer Extended',
    },
    {
      key: 'Offer Accepted',
      label: 'Offer Accepted',
      value: 'Offer Accepted',
    },
    {
      key: 'Offer Rejected',
      label: 'Offer Rejected',
      value: 'Offer Rejected',
    },
    {
      key: 'Application Rejected',
      label: 'Application Rejected',
      value: 'Application Rejected',
    }
  ];
  
  
  return (
    <Form onSubmit={onSubmitHandler}>
      <Form.Input
        required
        label="Role"
        placeholder="Role title"
        value={title}
        onChange={(event) => setTitle(event.target.value)}
      />
      <Form.Input
        required
        label="Company"
        placeholder="Company"
        value={company}
        onChange={(event) => setCompany(event.target.value)}
      />
      <div id="dateApplied">
        <label>
          <b>Date Applied</b>
        </label>
        <CalendarDateInput
          initialDate={date || ""}
          dateValue={dateValue}
          onSelection={setDate}
        />
      </div>

      <div style={{ paddingBottom: "1em" }}>
        <label>
          <b>Application Status</b>
        </label>
        <CreatableSelect
          isClearable
          fluid
          options={statusOptions}
          placeholder={status}
          onChange={(valueType: any, action) => {
            let { value } = valueType;
            setStatus(value);
          }}
        />
      </div>

           <Form.TextArea
        label="Description"
        placeholder="Description"
        value={description}
        onChange={(event) => setDescription(event.currentTarget.value)}
      />

      <div id="impDate">
        <label>
          <b>Important Date</b>
        </label>
        <Form.Field>
          <input
            placeholder="Alert Text"
            width={16}
            value={importantText}
            onChange={(event: {
              target: { value: React.SetStateAction<string> };
            }) => setImportantText(event.target.value)}
          />
        </Form.Field>
      </div>
      <div id="onDate">
        <label>
          <b>On</b>
        </label>
        <Form.Field>
          <CalendarDateInput
            initialDate={importantDate || ""}
            dateValue={importantDateValue}
            onSelection={setImportantDate}
          />
        </Form.Field>
      </div>
      <Form.TextArea
        label="Notes"
        placeholder="Notes"
        value={notes}
        onChange={(event) => setNotes(event.currentTarget.value)}
      />
      <Form.Input
        label="Where did you find this job?"
        placeholder="LinkedIn, BuiltinNYC, referral, etc."
        value={applicationFind}
        onChange={(event) => setApplicationFind(event.target.value)}
      />
      <Form.Input
        label="Where did you apply for this job?"
        value={applicationSite}
        placeholder="Company website, Monster.com, recruiter etc."
        onChange={(event) => setApplicationSite(event.target.value)}
      />
      <Form.Button floated="left" color="violet" type="submit">
        Save
      </Form.Button>
    </Form>
  );
};
export default ApplicationForm;
