import React, { useState, Fragment } from "react";
import { Header, Button, Container, Segment } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { db } from "../../base";
import { useParams } from "react-router";
import SiteHeader from "../../components/SiteHeader";
import SiteSidebar from "../../components/SiteSidebar";
import SiteFooter from "../../components/SiteFooter";
import ApplicationForm, { ApplicationDetailsProps } from "./ApplicationForm";
import BWLogo from "../../images/bwLogoCircle.svg";
import "./appDetails.css";


const EditApplication = () => {
  let history = useHistory();

  let [alreadyCalled, setAlreadyCalled] = useState(false);
  const [date, setDate] = useState<Date>();
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [importantDate, setImportantDate] = useState<Date>();
  const [importantText, setImportantText] = useState("");
  const [applicationSite, setApplicationSite] = useState("");
  const [applicationFind, setApplicationFind] = useState("");

  const cancelOnClick = () => {
    history.push("/application/" + id);
  };

  let { id } = useParams();

  let userId = localStorage.getItem("userId");
  if (userId && id && !alreadyCalled) {
    db.collection("users")
      .doc(userId)
      .collection("applications")
      .doc(id)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          console.log("data: ", doc.data());
          let data = doc.data();
          if (data) {
            let dateFromServer = data["date"];
            if (dateFromServer) {
              setDate(dateFromServer);
            }
            setTitle(data["title"] as string);
            setCompany(data["company"]);
            setStatus(data["status"]);
            setDescription(data["description"]);
            setNotes(data["notes"]);
            let importantDateFromServer = data["importantDate"];
            if (importantDateFromServer) {
              setImportantDate(importantDateFromServer);
            }
            let importantTextFromServer = data["importantText"];
            if (importantTextFromServer) {
              setImportantText(importantTextFromServer);
            }
            let applicationFindFromServer = data["applicationFind"];
            if (applicationFindFromServer) {
              setApplicationFind(applicationFindFromServer);
            }
            let applicationSiteFromServer = data["applicationSite"];
            if (applicationSiteFromServer) {
              setApplicationSite(applicationSiteFromServer);
            }
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
    setAlreadyCalled(true);
  }

  const newCompletionHandler = (props: ApplicationDetailsProps) => {
    let {
      date,
      title,
      company,
      status,
      description,
      importantDate,
      notes,
      importantText,
      applicationSite,
      applicationFind,
    } = props;

    let roleData: any = {};
    if (date) {
      roleData = { ...roleData, date };
    }

    if (title) {
      roleData = { ...roleData, title };
    }

    if (company) {
      roleData = { ...roleData, company };
    }

    if (status) {
      roleData = { ...roleData, status };
    }

    if (description) {
      roleData = { ...roleData, description };
    }

    if (importantText) {
      roleData = { ...roleData, importantText };
    }

    if (importantDate) {
      roleData = { ...roleData, importantDate };
    }

    if (notes) {
      roleData = { ...roleData, notes };
    }

    if (applicationSite) {
      roleData = { ...roleData, applicationSite };
    }

    if (applicationFind) {
      roleData = { ...roleData, applicationFind };
    }
    // Add a new document in the user's application collection
    let userId = localStorage.getItem("userId");
    if (userId && id) {
      db.collection("users")
        .doc(userId)
        .collection("applications")
        .doc(id)
        .set(roleData)
        .then(function () {
          console.log("Document successfully written!");
          history.push("/application/" + id);
        })
        .catch(function (error) {
          alert("Error writing document: " + error);
        });
    }
  };

  const componentProps = {
    date,
    title,
    company,
    status,
    description,
    importantDate,
    notes,
    importantText,
    applicationSite,
    applicationFind,
  };

  return (
    <Fragment>
      <SiteHeader />
      <SiteSidebar /> 
      <div className="wrapper fixed">
        <Segment style={{ zIndex: 200 }}>
          <Container >
            <div className="centered">
              <img
                className="centered"
                src={BWLogo}
                alt="Black and white climbroad logo"
                height="72px"
                width="72px"
              />
            </div>
            <Header as="h2" icon textAlign="center" id="appHeader">
              <Header.Content>{company + " - " + title}</Header.Content>
            </Header>
            <ApplicationForm
              handler={newCompletionHandler}
              {...componentProps}
            />
            <div className="cancelButton">
              <Button floated="right" type="cancel" onClick={cancelOnClick}>
                Cancel
              </Button>
            </div>
          </Container>
        </Segment>
      </div>
      <SiteFooter belowContent/>
    </Fragment>
  );
};
export default EditApplication;
