import { JobInfo } from "./ApplicationListScreen";

export function alphabeticalSort(a: JobInfo, b: JobInfo) {
  if (a.company.toLowerCase() === b.company.toLowerCase()) {
    return 0;
  } else {
    return a.company.toLowerCase() < b.company.toLowerCase() ? -1 : 1;
  }
}

export function prioritySort(a: JobInfo, b: JobInfo) {
  //need to make sure date sorting accounts for entries w/o dates
  return a.dateAlert || b.dateAlert
    ? !a.dateAlert
      ? 1
      : !b.dateAlert
      ? -1
      : compareDates(a.dateAlert, b.dateAlert)
    : 0;
}

export function dateSort(a: JobInfo, b: JobInfo) {
  const dateA = a.dateApplied;
  const dateB = b.dateApplied;
  return compareDates(dateA, dateB);
}

export function compareDates(dateA: string, dateB: string) {
  if (!dateA) {
    return !dateB ? 0 : -1;
  }
  if (!dateB) {
    return 1;
  }

  return new Date(dateA).getTime() - new Date(dateB).getTime();
}
