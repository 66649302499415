import React, { useState, Fragment } from "react";
import { Header, Button, Container, Segment } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { db } from "../../base";
import SiteFooter from "../../components/SiteFooter";
import BWLogo from "../../images/bwLogoCircle.svg";
import ApplicationForm, { ApplicationDetailsProps } from "./ApplicationForm";
import SiteHeader from "../../components/SiteHeader";
import SiteSidebar from "../../components/SiteSidebar";
import "./appDetails.css";

const AddApplication = () => {
  let history = useHistory();

  const [date] = useState<Date>();
  const [title] = useState("");
  const [company] = useState("");
  const [status] = useState("");
  const [description] = useState("");
  const [importantDate] = useState<Date>();
  const [notes] = useState("");
  const [importantText] = useState("");
  const [applicationSite] = useState("");
  const [applicationFind] = useState("");

  const cancelOnClick = () => {
    history.push("/jobList");
  };

  const newCompletionHandler = (props: ApplicationDetailsProps) => {
    let {
      date,
      title,
      company,
      status,
      description,
      importantDate,
      notes,
      importantText,
      applicationSite,
      applicationFind,
    } = props;

    let roleData: any = {};

    if (date) {
      roleData = { ...roleData, date };
    }

    if (title) {
      roleData = { ...roleData, title };
    }

    if (company) {
      roleData = { ...roleData, company };
    }

    if (status) {
      roleData = { ...roleData, status };
    }

    if (description) {
      roleData = { ...roleData, description };
    }

    if (importantText) {
      roleData = { ...roleData, importantText };
    }

    if (importantDate) {
      roleData = { ...roleData, importantDate };
    }

    if (notes) {
      roleData = { ...roleData, notes };
    }

    if (applicationSite) {
      roleData = { ...roleData, applicationSite };
    }

    if (applicationFind) {
      roleData = { ...roleData, applicationFind };
    }

    // Add a new document in the user's application collection
    let userId = localStorage.getItem("userId");
    if (userId) {
      db.collection("users")
        .doc(userId)
        .collection("applications")
        .add(roleData)
        .then(function () {
          history.push("/jobList");
        })
        .catch(function (error) {
          alert("Error writing document: " + error);
        });
    }
  };

  const componentProps = {
    date,
    title,
    company,
    status,
    description,
    importantDate,
    notes,
    importantText,
    applicationSite,
    applicationFind,
  };

  return (
    <Fragment>
      <SiteHeader />
      <SiteSidebar /> 
      <div className="wrapper fixed">
        <Segment style={{ zIndex: 200 }}>
          <Container >
            <div className="centered">
              <img
                className="centered"
                src={BWLogo}
                alt="Black and white climbroad logo"
                height="72px"
                width="72px"
              />
            </div>
            <Header
              className="aappHeader"
              as="h2"
              icon
              textAlign="center"
            >
              <Header.Content>Add Application</Header.Content>
            </Header>
            <ApplicationForm
              handler={newCompletionHandler}
              {...componentProps}
            />
            <div className="cancelButton">
              <Button floated="right" type="cancel" onClick={cancelOnClick}>
                Cancel
              </Button>
            </div>
          </Container>
        </Segment>
      </div>
      <SiteFooter belowContent/>
    </Fragment>
  );
};

export default AddApplication;
