import React from "react";
import { DateInput } from "semantic-ui-calendar-react";

interface CalendarDateInputProps {
  initialDate: string | Date;
  dateValue: string;
  onSelection: (selection: Date) => void;
}

const CalendarDateInput = ({
  initialDate,
  dateValue,
  onSelection,
}: CalendarDateInputProps) => {
  const handleChange = (e: React.SyntheticEvent<HTMLElement>, data: any) => {
    let dateSelected = data.value as Date;
    if (dateSelected) {
      onSelection(dateSelected);
    }
  };

  return (
    <DateInput
      name="date"
      initialDate={initialDate}
      dateFormat="MM-DD-YYYY"
      placeholder="Date"
      value={dateValue}
      iconPosition="left"
      onChange={handleChange}
      autoComplete="off"
    />
  );
};

export default CalendarDateInput;
