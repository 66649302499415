import React, { useState } from "react";
import { newSignUp } from "../../AuthUtils";
import { Form, Button, Container, Header, Segment } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import SiteFooter from "../../components/SiteFooter";
import SiteHeader from "../../components/SiteHeader";
import "./auth.css";

const SignUp = () => {
  let history = useHistory();

  const params = new URLSearchParams(history.location.search);
  const paramSignUpCode = params.get("code");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signUpCode, setSignUpCode] = useState(paramSignUpCode);
  const [confirmPass, setConfirmPass] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const signUpOnClick = () => {
    if (!email || !password || !confirmPass || !signUpCode) {
      alert("Please enter all fields");
      return;
    }

    if (password !== confirmPass) {
      alert("The passwords enter do not match.");
      return;
    }

    if (password.length < 8) {
      alert("Please enter a password over 8 characters.");
      return;
    }

    setIsLoading(true);
    newSignUp(email, password, signUpCode)
      .then(() => {
        setIsLoading(false);
        history.push("/jobList");
      })
      .catch((error) => {
        setIsLoading(false);
        const message: string =
          error.message ||
          "Oops! Something went wrong. Make sure everything is correct.";
        alert("Error: " + message);
      });
  };

  return (
    <>
      <SiteHeader />
      <div className="forms">
        <Segment>
          <Container>
            <Header as="h2" id="welcome">
              Welcome!
            </Header>
            <Form id="newInput">
              <Form.Input
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Input
                label="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Form.Input
                label="Confirm Password"
                type="password"
                onChange={(e) => setConfirmPass(e.target.value)}
              />
              <Form.Input
                label="Sign Up Code"
                value={signUpCode}
                onChange={(e) => setSignUpCode(e.target.value)}
              />
              <Form.Field>
                {
                  <div>
                    By continuing, you agree to ClimbRoad's{" "}
                    <a href="/terms-of-use" target="_blank">
                      Terms of Use
                    </a>{" "}
                    and confirm that you have read ClimbRoad's{" "}
                    <a href="/privacy-policy" target="_blank">
                      Privacy Policy
                    </a>
                    .
                  </div>
                }
              </Form.Field>
              <Button primary loading={isLoading} onClick={signUpOnClick}>
                Sign Up
              </Button>
            </Form>
          </Container>
        </Segment>
      </div>

      <SiteFooter belowContent/>
    </>
  );
};

export default SignUp;
