import firebase from "./base";

export const newSignUp = async (
  email: string,
  password: string,
  signUpCode: string
) => {
  var signUpUser = firebase.functions().httpsCallable("userAttemptToSignUp");
  await signUpUser({ email, password, signUpCode });
  return login(email, password);
};

export const sendEmailVerification = () => {
  return firebase.auth().currentUser?.sendEmailVerification();
};

export const login = async (email: string, password: string) => {
  try {
    const login = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const userId = login.user?.uid;
    if (userId) {
      localStorage.setItem("userId", userId);
    }
    return;
  } catch (error) {
    throw Error(
      "Unable to login. Please make sure your email and password are correct."
    );
  }
};

export const signOutUser = async () => {
  await firebase.auth().signOut();
  localStorage.clear();
  return;
};
