import React, { useState } from "react";
import firebase from "../../base";
import { Form, Header, Segment, Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import SiteFooter from "../../components/SiteFooter";
import SiteHeader from "../../components/SiteHeader";
import Logo from "../../components/Logo";
import "./auth.css";

const PasswordResetRequest = () => {
  let history = useHistory();

  const [email, setEmail] = useState("");

  var auth = firebase.auth();

  const resetOnClick = () => {
    if (!email) {
      alert("Please enter your email");
      return;
    }
    auth
      .sendPasswordResetEmail(email)
      .then(function () {
        // Email sent.
        history.push("/");
      })
      .catch(function (error) {
        var errorMessage = error.message;
        alert(errorMessage);
        // An error happened.
      });
  };

  return (
    <>
      <SiteHeader />
      <Header as="h2" id="logoHead" icon textAlign="center">
        <Logo height="150" width="150" />
        <Header.Content>ClimbRoad</Header.Content>
      </Header>
      <div id="entry">
        <p id="recovery">We'll send a recovery link to</p>
        <Segment>
          <Form>
            <Form.Input
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
            />

            <Button primary onClick={resetOnClick}>
              Send reset email
            </Button>
          </Form>
        </Segment>
      </div>

      <SiteFooter />
    </>
  );
};

export default PasswordResetRequest;
