import React from "react";
import { Card, Icon } from "semantic-ui-react";

interface AppItemProps {
  id: string;
  companyName: any;
  dateApplied: any;
  description: any;
  dateAlert: any;
  textAlert: any;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const ApplicationItem = ({
  id,
  companyName,
  dateApplied,
  description,
  dateAlert,
  textAlert,
  onClick,
}: AppItemProps) => {
  return (
    <Card key={parseInt(id)} onClick={onClick} style={{ boxShadow: "none" }}>
      <Card.Content>
        <Card.Header textAlign="left">{companyName}</Card.Header>
        <Card.Meta textAlign="left">{dateApplied}</Card.Meta>
        <Card.Description textAlign="left">{description}</Card.Description>
      </Card.Content>
      {(textAlert || dateAlert) && (
        <Card.Content extra>
          <Card.Description>
            <Icon name="thumbtack" color="red" /> {textAlert} on {dateAlert}
          </Card.Description>
        </Card.Content>
      )}
    </Card>
  );
};

export default ApplicationItem;
