import React, { useState } from "react";
import { db } from "../../base";
import { Card } from "semantic-ui-react";
import SiteFooter from "../../components/SiteFooter";
import SiteHeader from "../../components/SiteHeader";
import SiteSidebar from "../../components/SiteSidebar";
import { FeedCheckInCard, FeedEventReminderCard, FeedFactCard, FeedGoalCard, FeedQACard, FeedTipCard } from "./cards/FeedTipCard";
import "./cards/Tips.css";

enum FeedItemTypes {
  tip = "TIP",
  fact = "FACT",
  q_a = "Q_A",
  check_in = "CHECK_IN",
  goal_set = "GOAL_SET",
  event_reminder = "EVENT_REMINDER",
}

export interface FeedItemSource {
  source_link: string;
  source_title: string;
}

export interface FeedItem {
  item_id: string;
  item_type: FeedItemTypes;
  item_subtype: string;
  title: string;
  body?: string;
  tags: [string];
  source?: FeedItemSource;
  application_id?: number;
}

const FeedScreen = () => {
  let [alreadyCalled, setAlreadyCalled] = useState(false);
  let [items, setItems] = useState<FeedItem[]>([]);

  var feedQuery = db.collection("feed_items")

  const onXClick = (item: FeedItem): void => {
    setItems(items.filter(i => i.item_id !== item.item_id));
  }

  if (!alreadyCalled) {
    let arr: FeedItem[] = [];
    setAlreadyCalled(true);

    feedQuery.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let data = doc.data();

          let feedItemInfo: FeedItem = {
            item_id: doc.id,
            item_type: data["item_type"],
            item_subtype: data["sub_type"],
            title: data["title"],
            body: data["body"],
            tags: data["tags"],
            application_id: data["application_id"],
          };

          let sourceData = data["source"];
          if (sourceData) {
            let source: FeedItemSource = {
              source_link: sourceData["source_link"],
              source_title: sourceData["source_title"],
            };
            feedItemInfo["source"] = source;
          }

          arr.push(feedItemInfo);
          setItems(items.concat(arr));
        });
      });
  }

  return (
    <>
      <SiteHeader />
      <SiteSidebar /> 
      <div className="tipsContainer">
        <Card.Group
          centered
          itemsPerRow="3"
          style={{ margin: "1em 0", overflow: "auto" }}
        >
          {items.slice(0, 6).map((item: FeedItem) => {
            let itemType: FeedItemTypes = item.item_type;
            switch (itemType) {
              case FeedItemTypes.tip:
                return <FeedTipCard item={item} onXClick={onXClick} />;

              case FeedItemTypes.fact:
                return <FeedFactCard item={item} onXClick={onXClick} />;

              case FeedItemTypes.q_a:
                return <FeedQACard item={item} onXClick={onXClick} />;

              case FeedItemTypes.check_in:
                return <FeedCheckInCard item={item} onXClick={onXClick} />;

              case FeedItemTypes.goal_set:
                return <FeedGoalCard item={item} onXClick={onXClick} />;
                
              case FeedItemTypes.event_reminder:
                return <FeedEventReminderCard item={item} onXClick={onXClick} />;

            }
            
          })}
        </Card.Group>
      </div>
      <SiteFooter belowContent/>
    </>
  );
};

export default FeedScreen;

