import React from "react";

import { useHistory } from "react-router-dom";
import { Card } from "semantic-ui-react";

import { JobInfo } from "../ApplicationListScreen";
import ApplicationItem from "./ApplicationItem";

type ApplicationListProps = {
  apps: JobInfo[];
};

const ApplicationList = ({ apps }: ApplicationListProps) => {
  let history = useHistory();

  const goToDetail = (appId: string) => {
    history.push("/application/" + appId);
  };

  return (
    <Card.Group centered style={{ margin: "1em 0", overflow: "auto" }}>
      {apps.map((job: JobInfo) => (
        <ApplicationItem
          key={job.id}
          id={job.id}
          companyName={job.company}
          dateApplied={job.dateApplied}
          description={job.role}
          dateAlert={job.dateAlert}
          textAlert={job.textAlert}
          onClick={() => goToDetail(job.id)}
        />
      ))}
    </Card.Group>
  );
};

export default ApplicationList;
