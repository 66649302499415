import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Container, Header, Segment } from "semantic-ui-react";

import { login } from "../../AuthUtils";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";
import "./auth.css";

const Login = () => {
  let history = useHistory();

  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const loginOnClick = () => {
    if (!email) {
      alert("Please enter your email");
      return;
    }

    if (!password) {
      alert("Please enter your password");
      return;
    }

    setIsLoading(true);
    login(email, password)
      .then(function () {
        setIsLoading(false);
        history.push("/jobList");
      })
      .catch(function (error) {
        setIsLoading(false);
        alert("Oops! " + error.message);
        return;
      });
  };

  return (
    <>
      <SiteHeader />
      <div className="forms">
        <Segment>
          <Container>
            <Header as="h2" id="welcome">
              Welcome Back!
            </Header>
            <Form>
              <Form.Input
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Input
                label="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <a href="/passwordReset" target="_blank">
                Forgot Password?
              </a>
              <div id="loginButton">
                <Button
                  primary
                  loading={isLoading}
                  onClick={loginOnClick}
                  id="btn"
                >
                  Login
                </Button>

                <div id="noAccount">
                  <a href="/signup" target="_blank">
                    Don't have an account?
                  </a>
                </div>
              </div>
            </Form>
          </Container>
        </Segment>
      </div>
      <SiteFooter belowContent/>
    </>
  );
};

export default Login;
