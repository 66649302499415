import React, { useState } from "react";
import {
  Header,
  List,
  Button,
  Segment,
  Container,
  Icon,
} from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import DButton from "../../components/DeleteButton";
import { db } from "../../base";
import SiteFooter from "../../components/SiteFooter";
import SiteHeader from "../../components/SiteHeader";
import BWLogo from "../../images/bwLogoCircle.svg";
import Linkify from "linkifyjs/react";
import AddToCalendar from "@culturehq/add-to-calendar";
import "@culturehq/add-to-calendar/dist/styles.css";
import "./appDetails.css";
import SiteSidebar from "../../components/SiteSidebar";

const ApplicationDetails = () => {
  let history = useHistory();
  let { id } = useParams();

  let [alreadyCalled, setAlreadyCalled] = useState(false);
  let [date, setDate] = useState();
  let [title, setTitle] = useState();
  let [company, setCompany] = useState();
  let [status, setStatus] = useState();
  let [description, setDescription] = useState();
  let [notes, setNotes] = useState();
  let [importantDate, setImportantDate] = useState<Date>();
  let [importantText, setImportantText] = useState();
  let [applicationSite, setApplicationSite] = useState("");
  let [applicationFind, setApplicationFind] = useState("");

  let userId = localStorage.getItem("userId");
  if (userId && id && !alreadyCalled) {
    db.collection("users")
      .doc(userId)
      .collection("applications")
      .doc(id)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          let data = doc.data();
          if (data) {
            setDate(data["date"]);
            setTitle(data["title"]);
            setCompany(data["company"]);
            setStatus(data["status"]);
            setDescription(data["description"]);
            setNotes(data["notes"]);

            let importantDateFromServer = data["importantDate"];
            if (importantDateFromServer) {
              setImportantDate(importantDateFromServer);
            }
            let importantTextFromServer = data["importantText"];
            if (importantTextFromServer) {
              setImportantText(importantTextFromServer);
            }
            let applicationFindFromServer = data["applicationFind"];
            if (applicationFindFromServer) {
              setApplicationFind(applicationFindFromServer);
            }
            let applicationSiteFromServer = data["applicationSite"];
            if (applicationSiteFromServer) {
              setApplicationSite(applicationSiteFromServer);
            }
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
    setAlreadyCalled(true);
  }

  const RoleEdit = () => {
    history.push("/editApplication/" + id);
  };

  const deleteApplication = () => {
    if (userId && id) {
      db.collection("users")
        .doc(userId)
        .collection("applications")
        .doc(id)
        .delete()
        .then(function () {
          
          history.push("/jobList");
        })
        .catch(function (error) {
          console.error("Error removing document: ", error);
          alert("Error deleting role. Please try again.");
        });
    }
  };

  const event = {
    name: importantText || "",
    startsAt: importantDate?.toLocaleString() || "",
    endsAt: importantDate?.toLocaleString() || "",
    details: notes || "",
    location: "",
  };

  return (
    <>
      <SiteHeader />
      <SiteSidebar />
      <div className="wrapper fixed">
        <Segment style={{ zIndex: 200 }}>
          <Container>
            <div className="centered">
              <img
                src={BWLogo}
                alt="Black and white climbroad logo"
                height="72px"
                width="72px"
                className="centered"
              />
            </div>
            <Header as="h2" icon textAlign="center" id="appHeader">
              <Header.Content>{company + " - " + title}</Header.Content>
            </Header>
            <Segment textAlign="center">
              <List relaxed size={"large"} horizontal>
                <List.Item>
                  <List.Header>Date Applied</List.Header>
                  {date}
                </List.Item>
                <List.Item>
                  <List.Header>Company</List.Header>
                  {company}
                </List.Item>
                <List.Item>
                  <List.Header>Role</List.Header>
                  {title}
                </List.Item>
                <List.Item>
                  <List.Header>Application Status</List.Header>
                  {status}
                </List.Item>
              </List>
            </Segment>
            <Segment>
              <Linkify>
                <List relaxed size={"large"}>
                  {(importantText || importantDate) && (
                    <List.Item>
                      <List.Header>
                        <Icon name="thumbtack" color="red" />
                        {importantText} on {importantDate}
                        <AddToCalendar event={event} children="" />
                      </List.Header>
                    </List.Item>
                  )}
                  <List.Item>
                    <List.Header>Description</List.Header>
                    <p className="whiteSpace">{description}</p>
                  </List.Item>

                  <List.Item>
                    <List.Header>Notes</List.Header>
                    <p className="whiteSpace">{notes}</p>
                  </List.Item>
                  {applicationFind && (
                    <List.Item>
                      <List.Header>Where did you find this job?</List.Header>
                      {applicationFind}
                    </List.Item>
                  )}
                  {applicationSite && (
                    <List.Item>
                      <List.Header>
                        Where did you apply for this job?
                      </List.Header>
                      {applicationSite}
                    </List.Item>
                  )}
                </List>
              </Linkify>
            </Segment>
            <div id="buttons">
              <Button floated="left" color="violet" onClick={RoleEdit}>
                Edit
              </Button>
              <DButton floated="right" onClick={deleteApplication} />
            </div>
          </Container>
        </Segment>
      </div>
      <SiteFooter belowContent/>
    </>
  );
};

export default ApplicationDetails;
