import React, { useState } from "react";
import { Button, Header, Grid, Image, Input, Popup } from "semantic-ui-react";

import appstack from "../../images/appstack.png";
import SiteFooter from "../../components/SiteFooter";
import SiteHeader from "../../components/SiteHeader";
import Logo from "../../components/Logo";
import "./HomePage.css";
import { db } from "../../base";

const HomePage = () => {
  
  let emailData: any ={};
  const [emailAddress, setEmailAddress] = useState("")
  

  const submitEmail = () => {
        if (emailAddress) {
      emailData={...emailData, emailAddress}
    db.collection("email_list")
        .doc(emailAddress)
        .set(emailData)
        .catch(function (error) {
          alert("Error writing document: " + error);
        });
      };
     };

  return (
        <>
    
     <title>ClimbRoad: Your Digital Career Coach</title>
     <meta name="description" content="The digital career coach and job tracker that helps you organize job applications, 
     record career achievements and stay motivated while you advance your career.  Sign up today!"/>
      
      <SiteHeader />
      <div id="mainLogo" className="logoGreen">
        <Logo height="320" width="320" />

        <Header as="h1" textAlign="center" id="homeHeader">
         Blaze your perfect career path.
        </Header>
        <Header as="h3" id="homeSubtitle">
          Take your job hunt and professional life to new heights with ClimbRoad.
        </Header>
      </div>
      <div className="logoSoftGreen">
        <Grid>
          <Grid.Column width={11}>
          <Header id="org2">
              Launching Soon!
            </Header>
            <p id="bullet">
              Want to advance your career? <br/> Having trouble keeping track of everything you've done at your current 
              job? <br /> Need something to keep you motivated on your path to greatness? <br/>  ClimbRoad is the service for you!
            </p>
            <br />
            <p id="snapshot">
              Let ClimbRoad help you blaze the trail to the career of your dreams.  Use the job 
              board to organize job applications. The success tracker can remember everything you do 
              in your day-to-day, even if you can't.  And get helpful tips and motivation from the feed to keep you going. 
            </p>
            <br />
            <Header as="h3" id="org">
            Be one of the first to get access to ClimbRoad by entering in your email address below!
            </Header>
            <br />
            <Input 
            action={
              <Popup
              content="We heard you! Stay tuned for more information!"
               on='click'
              pinned
              trigger={<Button size="big" color="green" content="Submit" onClick={submitEmail} />}
              />
              // <Button content="Submit" onClick={submitEmail}/>
            } 
            placeholder='Sign up for email alerts'
            size="big"
            value={emailAddress} 
            onChange={(event) => setEmailAddress(event.target.value)}
            />
            <br />
            <Header as="h3" id="org">
              Organize Your Job Hunt
            </Header>
            <p id="snapshot">
              See a clear snapshot of your job hunt instantly. Use ClimbRoad's <a href="/applicationtracker">Job Tracker</a> to sort
              applications and stay on top of impending deadlines.
            </p>
            <br />
            <Header as="h3" id="org1">
              Career Planning Made Easy
            </Header>
            <p id="track">
              You accomplish something everyday.  Record those achievements with ClimbRoad's <a href="/careerplanner">Career Planner</a> and 
              use them update your resume, set goals or even get a raise or promotion!
            </p>
          </Grid.Column>
          <Grid.Column width={5} className="centered">
            <Image
              src={appstack}
              verticalAlign="middle"
              size="large"
              className="centered"
              alt="shiny stack of documents"
            />
            
          </Grid.Column>
        </Grid>
      </div>
      <SiteFooter belowContent />

    
    </>
      );
};

export default HomePage;
