import React from "react";
import SiteHeader from "../../components/SiteHeader";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <>
     <SiteHeader/>

    <div style={{ padding: "3em" }}>
      <title>
        ClimbRoad - Privacy Policy
      </title>

     <body>
        <p className="p1">
          <span className="s1">
            <b>CLIMBROAD, INC.</b>
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p1">
          <span className="s1">
            <b>PRIVACY POLICY</b>
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p1">
          <span className="s1">
            <b>Last Updated: July 11, 2020</b>
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            This privacy policy (<b>Policy</b>) describes how Climbroad, Inc.
            and its related companies (<b>Company</b>) collect, use and share
            personal information of consumer users of this website,
           <a href="www.climbroad.com" target="_blank"> www.climbroad.com</a> (the <b>Site</b>).
            This Policy also applies to any
            of our other websites that post this Policy. This Policy does not
            apply to websites that post different statements.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            <b>WHAT WE COLLECT</b>
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3">
            <b> </b>
          </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            We get information about you in a range of ways.
            <span className="Apple-converted-space"></span>
          </span>
        </p>
        <p className="p4">
          <span className="s3">
            <b> </b>
          </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p5">
          <span className="s1">
            <b>Information You Give Us.</b> We collect your‎ email address,
            password as well as other information you directly give us on our
            Site.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p4">
          <span className="s3">
            <b> </b>
          </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p5">
          <span className="s1">
            <b>Information We Get From Others.</b> We may get information about
            you from other sources. We may add this to information we get from
            this Site.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p4">
          <span className="s3">
            <b> </b>
          </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p5">
          <span className="s1">
            <b>Information Automatically Collected.</b> We automatically log
            information about you and your computer. For example, when visiting
            our Site, we log your computer operating system type, browser type,
            browser language, the website you visited before browsing to our
            Site, pages you viewed, how long you spent on a page, access times
            and information about your use of and actions on our Site.
            <span className="Apple-converted-space"></span>
          </span>
        </p>
        <p className="p6">
          <span className="s3">
            <b> </b>
          </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p5">
          <span className="s1">
            <b>Cookies.</b> We may log information using "cookies." Cookies are
            small data files stored on your hard drive by a website. We may use
            both session Cookies (which expire once you close your web browser)
            and persistent Cookies (which stay on your computer until you delete
            them) to provide you with a more personal and interactive experience
            on our Site. This type of information is collected to make the Site
            more useful to you and to tailor the experience with us to meet your
            special interests and needs.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3">
            <b> </b>
          </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            <b>USE OF PERSONAL INFORMATION</b>
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            We use your personal information as follows:
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p7">
          <span className="s4"></span>
          <span className="s5"> </span>
          <span className="s1">
            We use your personal information to operate, maintain, and improve
            our sites, products, and services.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p7">
          <span className="s4"></span>
          <span className="s5"> </span>
          <span className="s1">
            We use your personal information to respond to comments and
            questions and provide customer service.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p7">
          <span className="s4"></span>
          <span className="s5"> </span>
          <span className="s1">
            We use your personal information to send information including
            confirmations, invoices, technical notices, updates, security
            alerts, and support and administrative messages.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p7">
          <span className="s4"></span>
          <span className="s5"> </span>
          <span className="s1">
            We use your personal information to communicate about promotions,
            upcoming events, and other news about products and services offered
            by us and our selected partners.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p7">
          <span className="s4"></span>
          <span className="s5"> </span>
          <span className="s1">
            We use your personal information to link or combine user information
            with other personal information.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p7">
          <span className="s4"></span>
          <span className="s5"> </span>
          <span className="s1">
            We use your personal information to protect, investigate, and deter
            against fraudulent, unauthorized, or illegal activity.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p7">
          <span className="s4"></span>
          <span className="s5"> </span>
          <span className="s1">
            We use your personal information to provide and deliver products and
            services customers request.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3">
            <b> </b>
          </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            <b>SHARING OF PERSONAL INFORMATION</b>
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            We may share personal information as follows:
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p7">
          <span className="s4"></span>
          <span className="s5"> </span>
          <span className="s1">
            We may share personal information with your consent. For example,
            you may let us share personal information with others for their own
            marketing uses. Those uses will be subject to their privacy
            policies.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p7">
          <span className="s4"></span>
          <span className="s5"> </span>
          <span className="s1">
            We may share personal information when we do a business deal, or
            negotiate a business deal, involving the sale or transfer of all or
            a part of our business or assets. These deals can include any
            merger, financing, acquisition, or bankruptcy transaction or
            proceeding.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p7">
          <span className="s4"></span>
          <span className="s5"> </span>
          <span className="s1">
            We may share personal information for legal, protection, and safety
            purposes.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p8">
          <span className="s6">o</span>
          <span className="s5"> </span>
          <span className="s1">
            We may share information to comply with laws.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p8">
          <span className="s6">o</span>
          <span className="s5"> </span>
          <span className="s1">
            We may share information to respond to lawful requests and legal
            processes.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p8">
          <span className="s6">o</span>
          <span className="s5"> </span>
          <span className="s1">
            We may share information to protect the rights and property of
            Climbroad, Inc., our agents, customers, and others. This includes
            enforcing our agreements, policies, and terms of use.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p8">
          <span className="s6">o</span>
          <span className="s5"> </span>
          <span className="s1">
            We may share information in an emergency. This includes protecting
            the safety of our employees and agents, our customers, or any
            person.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p7">
          <span className="s4"></span>
          <span className="s5"> </span>
          <span className="s1">
            We may share information with those who need it to do work for us.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            We may also share aggregated and/or anonymized data with others for
            their own uses.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            <b>THIRD-PARTY PROVIDERS</b>
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3">
            <b> </b>
          </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">Google Analytics</span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualize and personalize the ads of its own advertising
            network.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            For more information on the privacy practices of Google, please
            visit the Google Privacy &amp; Terms web page:
            <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer"> https://policies.google.com/privacy?hl=en</a>
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3">‍</span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">Firebase</span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            Firebase is analytics, hosting, and database service provided by
            Google Inc., which includes Firebase Authentication (FA), Cloud
            Function for Firebase, Firebase Crashlytics, Firebase Hosting,
            Firebase Performance Monitoring and Firebase Realtime Database. FA
            uses cookies to enable a faster login process. FA supports
            authentication using passwords, popular federated identity providers
            like Google and Facebook. Without the use of an authentication token
            stored in a cookie you would have to provide a username/password on
            each page request. These cookies identify who you are for the length
            of the session and are only used on our Web App. For more
            information about Firebase and their cookies usage, visit
          <a href="https://www.firebase.com/terms/privacy-policy.html" target="_blank" rel="noopener noreferrer"> https://www.firebase.com/terms/privacy-policy.html</a>
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            You may opt-out of certain Firebase features through your mobile
            device settings, such as your device advertising settings or by
            following the instructions provided by Google in their Privacy
            Policy: 
           <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer"> https://policies.google.com/privacy?hl=en</a>
          </span>
          <span className="s2">
            <br />
            <br/>
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            We also encourage you to review the Google's policy for safeguarding
            your data: 
            <a href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="noopener noreferrer"> https://support.google.com/analytics/answer/6004245</a>
          </span> 
          <br/>
          <br/>
          <span className="s1">
            For more information on what type of information Firebase collects,
            please visit please visit the Google Privacy &amp; Terms web page: 
          <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer"> https://policies.google.com/privacy?hl=en</a>
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3">
            <b> </b>
          </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            <b>INFORMATION CHOICES AND CHANGES</b>
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            Our marketing emails tell you how to opt-out. If you opt out, we may
            still send you non-marketing emails. Non-marketing emails include
            emails about your accounts and our business dealings with you.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            You may send requests about personal information to our Contact
            Information below. You can request to change contact choices,
            opt-out of our sharing with others, and update your personal
            information.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            You can typically remove and reject cookies from our Site with your
            browser settings. Many browsers are set to accept cookies until you
            change your settings. If you remove or reject our cookies, it could
            affect how our Site works for you.
            <span className="Apple-converted-space"></span>
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            <b>CONTACT INFORMATION.</b> We welcome your comments or questions
            about this privacy policy. You may also contact us by email at:
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            <a href="mailto:climbroadinc+privacy@gmail.com">
              Climbroadinc+privacy@gmail.com
            </a>
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
        <p className="p2">
          <span className="s3"> </span>
          <span className="s1">
            <br />
          </span>
        </p>
        <p className="p3">
          <span className="s1">
            <b>CHANGES TO THIS PRIVACY POLICY.</b> We may change this privacy
            policy. If we make any changes, we will change the Last Updated date
            above.
          </span>
          <span className="s2">
            <br />
          </span>
        </p>
      </body>
    </div>
    </>
  );
};

export default PrivacyPolicy;
