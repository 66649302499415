import React from "react";
import { Grid, Segment, Header } from "semantic-ui-react";
import editIcon from "../../images/editIcon.svg";
import addIcon from "../../images/addIcon.svg";
import completionDeletionIcon from "../../images/completionDeletionIcon.svg";
import "./HowTo.css";

const HowToComponent = () => {
  return (
    <Segment
      style={{
        backgroundColor: "white",
        margin: "1em 20%",
      }}
    >
      <Grid columns={3} width="60%">
        <Grid.Column className="paddedRow">
          <div>
            <Header as="h4" dividing>
              Step 1
            </Header>
            <div className="centered">
              <img alt="editIcon" src={editIcon} height="126" />
            </div>
            <Header as="h4">Applied somewhere new?</Header>
            <p>Add the application details to your job board.</p>
          </div>
        </Grid.Column>

        <Grid.Column className="paddedRow">
          <Header as="h4" dividing>
            Step 2
          </Header>
          <div className="centered">
            <img alt="addIcon" src={addIcon} height="126" />
          </div>
          <Header as="h4">New details?</Header>
          <p>Edit your application to reflect the latest status.</p>
        </Grid.Column>

        <Grid.Column className="paddedRow">
          <Header as="h4" dividing>
            Step 3
          </Header>
          <div className="centered">
            <img
              alt="completionDeletionIcon"
              src={completionDeletionIcon}
              height="126"
            />
          </div>
          <Header as="h4">Important Date coming up?</Header>
          <p>
            Add important dates to highlight upcoming events by editing the
            application
          </p>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default HowToComponent;
