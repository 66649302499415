import React, { useState } from "react";
import { db } from "../../base";
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import ReactSelect from "react-select";
import jobBoardIcon from "../../images/jobBoardIcon.svg";

import EmptyListHolder from "./components/EmptyListHolder";
import SiteFooter from "../../components/SiteFooter";
import {
  alphabeticalSort,
  prioritySort,
  dateSort,
} from "./ApplicationListUtils";
import ApplicationList from "./components/ApplicationList";
import SiteHeader from "../../components/SiteHeader";
import "./appList.css";
import SiteSidebar from "../../components/SiteSidebar";

export type JobInfo = {
  id: string;
  company: string;
  dateApplied: string;
  role: string;
  dateAlert: any;
  textAlert: string;
};

const JobList = () => {
  let history = useHistory();
  let [alreadyCalled, setAlreadyCalled] = useState(false);
  let [apps, setApps] = useState<JobInfo[]>([]);
  let userId = localStorage.getItem("userId");

  const addOnClick = () => {
    history.push("/addApp");
  };

  const options = [
    // { label: "Original List", value: 1 },
    { label: "Alphabetical Sort", value: 2 },
    { label: "Date Sort", value: 3 },
    { label: "Priority Sort", value: 4 },
  ];

  const callForSortedData = (value: number) => {
    if (value === 2) {
      // let sorted = apps.sort(sortMe);
      setApps(apps.slice().sort(alphabeticalSort));
    }

    if (value === 3) {
      setApps(apps.slice().sort(dateSort));
    }

    if (value === 4) {
      setApps(apps.slice().sort(prioritySort));
    }

    return null;
  };

  if (userId && !alreadyCalled) {
    let arr: JobInfo[] = [];
    setAlreadyCalled(true);
    db.collection("users")
      .doc(userId)
      .collection("applications")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots

          let data = doc.data();
          let jobInfo: JobInfo = {
            id: doc.id,
            company: data["company"],
            dateApplied: data["date"],
            role: data["title"],
            dateAlert: data["importantDate"],
            textAlert: data["importantText"],
          };
          arr.push(jobInfo);
          arr = arr.slice().sort(dateSort);
          setApps(apps.concat(arr));
        });
      });
  }

  const onSortOptionChange = (input: any) => {
    let { value } = input;
    callForSortedData(value);
  };

  return (
    <>
      <SiteHeader />
      <SiteSidebar />
      <div className="main fixed">
      <div className="boardWrapper">
        <div id="head">
          <img src={jobBoardIcon} alt="Logo" height="45" width="45" />
          <span id="jobBoard">Your Job Board</span>
        </div>
        {apps.length === 0 ? (
          <EmptyListHolder />
        ) : (
          <>
            <div id="select">
              <ReactSelect options={options} onChange={onSortOptionChange} />
            </div>

            <ApplicationList apps={apps} />
          </>
        )}
        <div className="centered">
          <Button color="violet" onClick={addOnClick}>
            Add Application
          </Button>
        </div>
      </div>
      </div>
      <SiteFooter belowContent/>
    </>
  );
};
export default JobList;
