import React, { useState } from "react";
import { Button, Header, Grid, Image, Input, Popup } from "semantic-ui-react";
import appstack from "../../images/appstack.png";
import SiteFooter from "../../components/SiteFooter";
import SiteHeader from "../../components/SiteHeader";
import AppDemoPage from "../../images/AppDemoPage.png";
import "./PromoPage.css";
import { db } from "../../base";

const JobBoardPromoPage = () => {
  let emailData: any ={};
  const [emailAddress, setEmailAddress] = useState("")
 
  const submitEmail = () => {
        if (emailAddress) {
      emailData={...emailData, emailAddress}
    db.collection("email_list")
        .doc(emailAddress)
        .set(emailData)
        .catch(function (error) {
          alert("Error writing document: " + error);
        });
      };
     };

  return (
        <>
    
     <title>ClimbRoad: Job Application Tracker</title>
     <meta name="description" content="Track all of your job applications with ClimbRoad.  Use ClimbRoad to stay on top 
     of important dates and details.  Sign up today!"/>
      
      <SiteHeader />
      <div id="mainLogo" className="logoBlue">
      <Image
              src={appstack}
              verticalAlign="middle"
              size="medium"
              className="centered"
              alt="shiny stack of documents"
            />

        <Header as="h1" textAlign="center" id="homeHeader">
         Keep track of your job applications, <br /> no matter how many there are.
        </Header>
        <Header as="h3" id="homeSubtitle">
          It takes more applications than ever to land the perfect job.  Use ClimbRoad to stay organized and on top
           of your game.
        </Header>
      </div>
      <div className="logoSoftBlue">
        <Grid>
          <Grid.Column width={11}>
          <Header as="h3" id="org">
              See Your Applications at a Glance
            </Header>
            <p id="snapshot">
              Always know where you are in your job search with ClimbRoad's Job Tracker.  Update the status of each application. 
              See when your next touchpoint is. Sort them to see the applications you care about, when you care about them.
                ClimbRoad helps you stay organized every step of the way.
              </p>
            <br />
            <Header as="h3" id="org">
              Streamline the Job Hunt
            </Header>
            <p id="snapshot">
             Never question if you've already applied to a job again.  Use ClimbRoad to remember where you found each position.
             Save the job description.  Ace the interview.  Set a reminder to follow up. Celebrate the offer!
            </p> 
            <br />
            <Header as="h3" id="org">
            Be one of the first to get access to ClimbRoad by entering in your email address below!
            </Header>
            <br />
            <Input 
            action={
              <Popup
              content="We heard you! Stay tuned for more information!"
               on='click'
              pinned
              trigger={<Button size="big" color="green" content="Submit" onClick={submitEmail} />}
              />
              // <Button content="Submit" onClick={submitEmail}/>
            } 
            placeholder='Sign up for email alerts'
            size="big"
            value={emailAddress} 
            onChange={(event) => setEmailAddress(event.target.value)}
            />
            
          </Grid.Column>
          <Grid.Column width={5} className="centered">
            <Image
              src={AppDemoPage}
              verticalAlign="middle"
              size="large"
              className="centered"
              alt="job tracker board example"
            />
            
            
          </Grid.Column>
        </Grid>
      </div>
      <SiteFooter belowContent />

    
    </>
      );
};

export default JobBoardPromoPage;
