import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../Auth";

const LoginRoute = ({ component: Component, ...rest }: any) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(routeProps: any) => {
        return currentUser ? (
          <Redirect to={"/"} />
        ) : (
          <Component {...routeProps} />
        );
      }}
    />
  );
};

export default LoginRoute;
