import React, { useContext } from "react";
import { AuthContext } from "../Auth";
import SignOutButton from "./SignOutButton";
import { Icon, Button } from "semantic-ui-react";
import { useLocation, useHistory } from "react-router-dom";
import "./header.css";
import Logo from "./Logo";

const SiteHeader = () => {
  const { currentUser } = useContext(AuthContext);
  let location = useLocation();
  const path = location.pathname;
  let dest = "";
  let history = useHistory();
  if (currentUser && (path.includes("application") ||
  path.includes("editApplication") ||
  path.includes("addApp"))){
    dest = "/jobList";
  } else if (currentUser) {
    dest = "/feed";
  } else {
    dest = "/home";
  }

  const signUpOnClick = () => {
    history.push("/signup");
  };

  const loginOnClick = () => {
    history.push("/login");
  };

  
  const showArrow = () => {
    const path = location.pathname;
    if (!path || path === "/") {
      return false;
    }
    if (
      path.includes("application") ||
      path.includes("addApp") ||
      path.includes("editApplication") ||
      path.includes("tracker") ||
      path.includes("planner")
    ) {
      return true;
    }

    return false;
  };

  const showLogin = () => {
    const path = location.pathname;
    if (path.includes("login")) {
      return false;
    }
    return true;
  };

  const showSignup = () => {
    if (location.pathname.includes("signup")) {
      return false;
    }
    return true;
  };

  return (
    <div className="logoGreen" id="lg">
      <a href={dest} id="title">
        {showArrow() && <Icon name="arrow left" id="arrow" />}
        <Logo height="32" width="32" />
        <span id="compName">ClimbRoad.</span>
      </a>
      {currentUser && <SignOutButton />}
      {!currentUser && (
        <div id="btn">
          {showSignup() && (
            <Button inverted onClick={signUpOnClick}>
              Sign Up
            </Button>
          )}
          {showLogin() && (
            <Button color="green" onClick={loginOnClick}>
              Login
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default SiteHeader;
