import React from "react";

// Firebase App (the core Firebase SDK) is always required and must be listed first
// import * as firebase from "firebase/app";
import "firebase/analytics";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from "./screens/auth/HomePage";
import FeedScreen from "./screens/feed/FeedScreen";
import JobList from "./screens/jobboard/ApplicationListScreen";
import AddApplication from "./screens/application/AddApplication";
import SignUp from "./screens/auth/SignUp";
import ApplicationDetails from "./screens/application/ApplicationDetails";
import EditApplication from "./screens/application/EditApplication";
import HowToPage from "./screens/HowTo/HowToScreen";
import PrivacyPolicy from "./screens/legal/PrivacyPolicy";
import TermsOfUse from "./screens/legal/TermsOfUse";
import { AuthProvider } from "./Auth";
import AboutUs from "./screens/AboutUs/AboutUsPage";
import PasswordResetRequest from "./screens/auth/PasswordResetRequest";
import Login from "./screens/auth/Login";
import { PrivateRoute, LoginRoute, HomeRoute } from "./routes/index";
import JobBoardPromoPage from "./screens/Landing Pages/JobBoardPromo";
import CareerTrackerPromoPage from "./screens/Landing Pages/CareerTrackerPromo";

function App() {
  return (
    <AuthProvider>
      <Router>
        <HomeRoute exact path="/" component={HomePage} />
        <HomeRoute exact path="/home" component={HomePage} />
        <Route exact path="/applicationtracker" component={JobBoardPromoPage} />
        <Route exact path="/careerplanner" component={CareerTrackerPromoPage} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-of-use" component={TermsOfUse} />
        <Route exact path="/passwordReset" component={PasswordResetRequest} />
        <Route exact path="/how-tos" component={HowToPage} />
        <Route exact path="/about-us" component={AboutUs} />
        <LoginRoute exact path="/login" component={Login} />
        <LoginRoute exact path="/signup" component={SignUp} />
        <PrivateRoute exact path="/jobList" component={JobList} />
        <PrivateRoute exact path="/addApp" component={AddApplication} />
        <PrivateRoute
          exact
          path="/application/:id/"
          component={ApplicationDetails}
        />
        <PrivateRoute
          exact
          path="/editApplication/:id/"
          component={EditApplication}
        />
        <PrivateRoute exact path="/feed" component={FeedScreen} />
      </Router>
    </AuthProvider>
  );
}

export default App;
