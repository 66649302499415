import React from "react";
import { Header, Segment } from "semantic-ui-react";
import HowToComponent from "../../HowTo/HowToComponent";

const EmptyListHolder = () => {
  return (
    <div>
      <Segment
        style={{
          backgroundColor: "white",
          margin: "1em 20%",
        }}
      >
        <Header as="h3" icon textAlign="center">
          <Header.Content>
            Get Started By Adding Your First Application
          </Header.Content>
        </Header>
      </Segment>
      <HowToComponent />
    </div>
  );
};

export default EmptyListHolder;
