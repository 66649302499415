import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "../Auth";
import HomePage from "../screens/auth/HomePage";
import JobList from "../screens/jobboard/ApplicationListScreen";

const HomeRoute = ({ component: Component, ...rest }: any) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(routeProps: any) => {
        return currentUser ? (
          <JobList {...routeProps} />
        ) : (
          <HomePage {...routeProps} />
        );
      }}
    />
  );
};

export default HomeRoute;
