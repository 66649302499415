import React from "react";
import logo from "../images/colorLogoCircle.svg";

interface LogoProps {
  height: string | number;
  width: string | number;
}

const Logo = ({ height, width }: LogoProps) => {
  return <img src={logo} alt="Logo" height={height} width={width} />;
};

export default Logo;
