import React from "react";
import "./sidebar.css";

const SiteSidebar = () => {
  return (
    <div className="sidenav">
      <a href="/feed">Feed</a>
      <a href="/jobList">Job Board</a>
    </div>
  );
};

export default SiteSidebar;
