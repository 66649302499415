import React from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import SiteFooter from "../../components/SiteFooter";
import SiteHeader from "../../components/SiteHeader";
import HowToComponent from "./HowToComponent";

import "./HowTo.css";

const HowToPage = () => {
  return (
    <>
      <SiteHeader />
      <div className="howToWrapper">
        <Header as="h1" className="centered" id="pageTitle">
          How-To's
        </Header>
        <HowToComponent />
      </div>
      <SiteFooter />
    </>
  );
};
export default HowToPage;
