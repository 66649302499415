import React from "react";
import { Container, List } from "semantic-ui-react";
import "./footer.css";

interface SiteFooterProps {
  belowContent?: boolean;
}

const SiteFooter = ({ belowContent }: SiteFooterProps) => {
  const footClass = belowContent ? "bottomFooter" : "stickyFooter";
  return (
    <div id="foot" className={footClass}>
      <Container>
        <List bulleted horizontal link>
          <List.Item content={<a href="/how-tos">How-To's</a>} />
          <List.Item content={<a href="/about-us">About Us</a>} />
          <List.Item
            content={
              <a href="mailto:climbroadinc+help@gmail.com">Contact Us</a>
            }
          />
          <List.Item content={<a href="/privacy-policy">Privacy Policy</a>} />
          <List.Item content={<a href="/terms-of-use">Terms of Use</a>} />
        </List>
      </Container>
    </div>
  );
};

export default SiteFooter;
