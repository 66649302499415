import React from "react";
import "./AboutUs.css";
import SiteFooter from "../../components/SiteFooter";
import SiteHeader from "../../components/SiteHeader";

const AboutUs = () => {
  return (
    <>
    <SiteHeader/>
<div style={{ padding: "3em" }}>
    <title>
      About ClimbRoad
    </title>
    
    <body>
      <p className="p1">
        
          <b>ClimbRoad is your online career coach!</b>
        <p/>
        
        <p className="p2">
        Whether you are looking for a new job or trying to take your current career to the next level,
        ClimbRoad is here for you. 
        ClimbRoad helps users track applications, reflect on career successes and plot their professional path.
        Stay motivated and accountable with ClimbRoad!
        </p>
        <br/>
        <p className="p2">
          Use the feed for tips and reminders on how to reach your career summit.
        </p>
        <br/>
        <p className="p2">
          The <a href="/jobList">job board</a> helps organize applications and remember important dates.
        </p>
        
        <p className="p1">
          With ClimbRoad you can reach the height of success!
        </p>
      </p>
    </body>
    
</div>
<SiteFooter belowContent/>
</>
  )
};

export default AboutUs;