import React, { useState } from "react";
import { Button, Header, Grid, Image, Input, Popup } from "semantic-ui-react";
import jobBoardIcon from "../../images/jobBoardIcon.svg";
import SiteFooter from "../../components/SiteFooter";
import SiteHeader from "../../components/SiteHeader";
import addicon from "../../images/addIcon.svg";
import "./PromoPage.css";
import { db } from "../../base";

const CareerTrackerPromoPage = () => {
 
  let emailData: any ={};
  const [emailAddress, setEmailAddress] = useState("")
  

  const submitEmail = () => {
        if (emailAddress) {
      emailData={...emailData, emailAddress}
    db.collection("email_list")
        .doc(emailAddress)
        .set(emailData)
        .catch(function (error) {
          alert("Error writing document: " + error);
        });
      };
     };

  return (
        <>
    
     <title>ClimbRoad: Career Success Planner</title>
     <meta name="description" content="Remember all your work accomplishments with ClimbRoad.  Track achievements and use them
      to refresh your resume, argue for a raise, or even get a promotion.  Sign up today!"/>
      
      <SiteHeader />
      <div id="mainLogo" className="logoBlue">
      <Image
              src={addicon}
              verticalAlign="middle"
              size="medium"
              className="centered"
              alt="documents with pencil"
            />

        <Header as="h1" textAlign="center" id="homeHeader">
         Do you remember what you did at work last year?  No? <br /> Let ClimbRoad remember for you.
        </Header>
        <Header as="h3" id="homeSubtitle">
          The best offense is a good defense.  Keep track of all your work accomplishments - projects completed,
           deals closed, trainings done, etc. - and use them to further your career!
        </Header>
      </div>
      <div className="logoSoftBlue">
        <Grid>
          <Grid.Column width={11}>
          <Header as="h3" id="org">
              Put Together Your Personal Career Portfolio
            </Header>
            <p id="snapshot">
              Putting your successes in one place makes advancing your career a breeze. Update resumes. Argue for a raise. 
              Snatch that promotion. Know exactly what you've done so you can plan where you're going!
              </p>
            <br />
            <Header as="h3" id="org">
              Reflect on Your Current Job
            </Header>
            <p id="snapshot">
             Are you being challenged at work?  Are they giving you the projects and responsibilities you need to advance?  
             Do you even like what you're doing?  Keeping a log of the efforts you put in at work makes answering these questions easy. 
             See what you thought, when you thought it, and know exactly how you feel and what you need.  With ClimbRoad. 

            </p> 
            <br />
            <Header as="h3" id="org">
            Be one of the first to get access to ClimbRoad by entering in your email address below!
            </Header>
            <br />
            <Input 
            action={
              <Popup
              content="We heard you! Stay tuned for more information!"
               on='click'
              pinned
              trigger={<Button size="big" color="green" content="Submit" onClick={submitEmail} />}
              />
              // <Button content="Submit" onClick={submitEmail}/>
            } 
            placeholder='Sign up for email alerts'
            size="big"
            value={emailAddress} 
            onChange={(event) => setEmailAddress(event.target.value)}
            />
            
          </Grid.Column>
          <Grid.Column width={5} className="centered">
            <Image
              src={jobBoardIcon}
              verticalAlign="middle"
              size="medium"
              className="centered"
              alt="job tracker board icon"
            />
            
          </Grid.Column>
        </Grid>
      </div>
      <SiteFooter belowContent />

    
    </>
      );
};

export default CareerTrackerPromoPage;
